<template>
  <v-container>
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" xd="12" sm="8" md="6" lg="4" xl="3">
          <v-form @submit.prevent="confirmar" ref="form" v-model="formValido" autocomplete="off">
            <v-card class="elevation-12">
              <v-toolbar flat>
                <v-spacer />
                <v-toolbar-title class="headline">Registro de Ponto</v-toolbar-title>
                <v-spacer />
                </v-toolbar>
                <v-card-text>
                  <v-text-field v-model="dataAtual" :readonly="true" label="Data/Hora" name="data"
                    prepend-icon="fas fa-clock" type="text"/>
                  <v-text-field v-model="funcionario.cpf" :rules="rules.cpf" label="CPF" name="cpf"
                    prepend-icon="fas fa-user" type="number" maxlength="11" autocomplete="off" required autofocus/>
                  <v-text-field v-model="funcionario.datanasc" :rules="rules.datanasc" label="Data Nascimento" name="datanasc"
                    prepend-icon="fas fa-calendar-day" type="number" maxlength="8" required/>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn type="submit" :disabled="!formValido" block color="primary">
                    <v-icon dark left>mdi-arrow-right</v-icon>Próximo
                  </v-btn>
                </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
        <br/>
      </v-row>
      <v-row align="center" justify="center">
        <p class="caption">Versão {{ sistema.versao }} - {{ sistema.atualizacao }}</p>
      </v-row>
    </v-container>


  </v-container>
</template>

<script>
import { httpSetToken } from "@/plugins/http";
export default {
  name: "Ponto",
  data() {
    return {
      formValido: false,
      rules: {
        cpf: [ v => { return (v.length == 11) || "Valor inválido"; } ],
        datanasc: [ v => { return (v && v.length == 8) || "Valor inválido"; } ],
      }
    };
  },

  computed: {
    sistema(){
      return this.$store.state.application.sistema;
    },
    funcionario(){
      return this.$store.state.ponto.funcionario;
    },
    dataAtual(){
      return this.$store.state.ponto.dataAtual;
    },
  },

  mounted() {
    this.$refs.form.resetValidation();
    // Atribuição da token da aplicação
    httpSetToken();
    this.$store.dispatch("ponto/dataAtual");
  },

  methods: {
    confirmar(){
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$store.dispatch("ponto/load", this.funcionario).then(() => {
        this.$router.push("/registro/confirmar");
      });
    },
    limpar(){
      this.$store.dispatch("ponto/reset");
    }
  },
};
</script>